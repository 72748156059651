import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import Hero from "../components/home/hero"
import { HeroSection } from "../components/landingPage/HeroSection"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { graphql } from "gatsby"
import CodeExample from "../components/home/CodeExample"
import { LandingPageFeatures } from "../components/landingPage/LandingPageFeatures"
import { CompatibleLibraries } from "../components/landingPage/CompatibleLibraries"

const useStyles = makeStyles(theme => ({
  mainArea: {
    marginBottom: "30px",
  },
  h2: {
    textAlign: "center",
    color: theme.palette.primary.main,
    marginTop: "50px",
    marginBottom: "20px",
    fontSize: "1.75rem",
    fontWeight: "600",
  },
  list: {
    listStyle: "circle",
  },
}))

const MarkdownLandingPage = ({ data, location }) => {
  const classes = useStyles()
  const [isFirstTranslated, setFirstTranslated] = useState(false)
  // const [isSecondTranslated, setSecondTranslated] = useState(false);
  const image = data

  return (
    <Layout>
      <HeroSection>
        <Hero
          title="Translate Markdown"
          subtitle="Translate your Documentation or Blog Posts"
          source="Signup HeroMarkdown"
        />
      </HeroSection>

      <Container maxWidth="lg" className={classes.mainArea}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.h2}>
              Translate Markdown from Documentations and Blogposts
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              Use Simpleen to translate your Markdown files like documentations
              and blog posts. It can be customized with a glossary and either
              used directly or via the API.
              <br />
              <br />
              Simply copy/paste your Markdown into our Web Translator and
              receive instant results. Simpleen manages the correct handling of
              the Markdown format, the context and the glossary.
              <br />
              <br />
              Save time and money in your multilingual projects by using
              Simpleen.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <GatsbyImage
              image={data.markdown.childImageSharp.gatsbyImageData}
              alt="Markdown Logo"
            />
          </Grid>
          <Grid item xs={12}>
            <CodeExample
              codeFirst={data.snippetExample}
              codeSecond={data.snippetExampleTranslated}
              langCodeFirst="en"
              langCodeSecond="de"
              isTranslated={isFirstTranslated}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" style={{ textAlign: "center" }}>
              <Button
                style={{ marginLeft: "auto", marginRight: "auto" }}
                variant="contained"
                color="primary"
                onClick={() => {
                  window &&
                    window.plausible &&
                    window.plausible(
                      isFirstTranslated
                        ? "Clicked Revert Example"
                        : "Clicked Translate Example"
                    )
                  setFirstTranslated(!isFirstTranslated)
                }}
              >
                {isFirstTranslated ? "Revert" : "Translate Example"}
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <CompatibleLibraries currentPage="markdown" />

      <LandingPageFeatures source="Signup ButtonMarkdown" />

      <Seo
        general={{
          title: "Translate markdown format",
          description:
            "Translate markdown documentations or blog post with machine translation",
          path: location.pathname,
          image: image.twitter.childImageSharp.gatsbyImageData.src,
        }}
      />
    </Layout>
  )
}

export const docuLPQuery = graphql`
  query DocuLPQuery {
    twitter: file(relativePath: { eq: "seo/SEOImageDefault.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          quality: 95
          placeholder: BLURRED
          layout: FIXED
        )
      }
    }
    markdown: file(relativePath: { eq: "logos/logo-markdown.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          quality: 95
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
    }
    snippetExample: markdownRemark(
      frontmatter: { slug: { eq: "/markdownCode" } }
    ) {
      html
    }
    snippetExampleTranslated: markdownRemark(
      frontmatter: { slug: { eq: "/markdownCodeTranslated" } }
    ) {
      html
    }
  }
`

export default MarkdownLandingPage
